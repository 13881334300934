import userData from '../../models/userModel';
import config from '../../config.json';
import { resolve } from 'path';

class LoginService {
    
    private user: userData;

    public constructor(userData: userData) {
        this.user = userData;
    }

    public getUserEntity(username: string) {
        return new Promise((resolve, reject) => {
            const request = {
                method: "GET"
            };
            fetch(config.endpoint + 'simplepayapi/logs/getentitybyusername?username=' + username,request)
            .then(async response => {
                const data = await response.json();
                resolve(data);
            }).catch(error => {
                console.log('Error message: ', error);
                reject(error);
            });
        });
    }

    public getUserDetails() {
        return new Promise(resolve => {
            const request = {
                method: "GET",
                headers: { 'Content-Type': 'application/json' }
            };
            fetch(config.endpoint + "users/info?DOLAPIKEY="+this.user.token,request)
            .then(async response => {
                const data = await response.json();
                resolve(data);
            });
        });
    }

    public async getUserCompany(entityID: any) {
        return new Promise(resolve => {
            const request = {
                method: "GET",
                headers: { 'Content-Type': 'application/json' }
            };
            fetch(config.endpoint + 'bbus/entities?DOLAPIKEY='+this.user.token,request)
            .then(async response => {
                const data = await response.json();
                const company = data.find((entityInfo: any) => {
                    return entityInfo.id.toString() === entityID.toString() ? entityInfo : null;
                });
                resolve(company);
            });
        });
    }


    public async getUserGoup() {
        return new Promise(resolve => {
            const request = {
                method: "GET",
                headers: { 'Content-Type': 'application/json' }
            };
            fetch(config.endpoint + 'bbus/getGroupId?DOLAPIKEY='+this.user.token,request)
            .then(async response => {
                const data = await response.json();
                resolve(data.fk_settlements_group);
            });
        });
    }

}

export default LoginService;