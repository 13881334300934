import './ComboListBox.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect } from 'react';
import optionData from './ComboListBox.interface';

const ComboListBox = (props: any) => {
    const defaultValue: optionData = {image: '', title: '', subtitle: '', value: '', id: 0, adultPrice: 0, childPrice: 0, netAdultPrice: 0, netChildPrice: 0, tax: 0, subproducts: []};

    const [items, setItems] = useState(props.listData);
    const [selectedItem, setSelectedItem] = useState(defaultValue);
    const [dropDownState, setDropDownState] = useState(false);

    const dropDownClick = () => {
        if (!props.disabled) {
            setDropDownState(!dropDownState);
        }
    };

    const setItem = (selected: any) => {
        if (!props.disabled) {
            setSelectedItem(selected);
            setDropDownState(!dropDownState);
            props.callbackHandler(selected);
        }
    };

    useEffect(() => {
    },[items,selectedItem]);

    const options = items.map((item: any,index: number) => {
        if (item.image) {
            return(
                <li key={index} className={ item.image ? 'comboListBoxOptionImaged' : 'comboListBoxOptionSimple' } onClick={() => setItem(item)}><img src={item.image} alt={item.title} /> <strong>{item.title}</strong><small>{item.subtitle}</small></li>
            )
        }
        else {
            return(
                <li key={index} className={ item.image ? 'comboListBoxOptionImaged' : 'comboListBoxOptionSimple' } onClick={() => setItem(item)}><strong>{item.title}</strong><small>{item.subtitle}</small></li>
            )
        }
    });

    return(
        <div className="comboListBoxContainer" onClick={dropDownClick}>
            <FontAwesomeIcon className="angle" icon={faAngleDown} />
            <ul className="selectedOption">
                <li className={ selectedItem.image ? 'comboListBoxOptionSelectedImaged' : 'comboListBoxOptionSelectedSimple' }>
                    { selectedItem.image ? <img src={selectedItem.image} alt={selectedItem.title} /> : '' }
                    <strong>{selectedItem.title}</strong>
                    <small>{selectedItem.subtitle}</small>
                </li>
            </ul>
            {dropDownState && 
                <ul className="optionsList">
                    {options}
                </ul>
            }
        </div>
    );
};

export default ComboListBox;