import './login.scss'
import { Button, Container, Row, Col, Form, Toast, ToastBody, ToastHeader, ToastContainer } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import userData from '../../models/userModel';
import config from '../../config.json';
import LoginService from './LoginService';

const Login = () => {
    const [user, setUser] = useState<userData>({} as userData);
    const [passVisible, setPassVisible] = useState('password');
    const [errorMessage, setErrorMessage] = useState('');
    const [errorMessageVisibility, setErrorMessageVisibility] = useState(false);
    const [loading, setLoading] = useState(false);

    const loginService = new LoginService(user);

    const onUserChange = (event: any) => {
        const userData: userData = user;
        userData.login = event.target.value;
        setUser(userData);
    };

    const onPassChange = (event: any) => {
        const userData: userData = user;
        userData.password = event.target.value;
        setUser(userData);
    };

    const authUser = async () => {
        if (user.login && user.password) {
            setErrorMessage('');
            setErrorMessageVisibility(false);
            setLoading(!loading);
            const userData: userData = user;

            await loginService.getUserEntity(user.login).then(entity => {
                userData.entity = entity;
                const request = {
                    method: "POST",
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(userData)
                };
                fetch(config.endpoint + 'login',request)
                .then(async response => {
                    const data = await response.json();
                    if ('success' in data) {
                        userData.token = data.success.token;
                        const loginService = new LoginService(userData);
                        let groupValue: string;
                        await loginService.getUserGoup().then((groupID: any) => {
                            groupValue = groupID;
                        });
                        await loginService.getUserDetails().then((userDetails: any) => {
                            loginService.getUserCompany(userData.entity).then((companyData: any) => {
                                userData.groupID = groupValue;
                                userData.company_name = companyData.label;
                                setUser(userData);
                                localStorage.setItem('userData',JSON.stringify(user));
                                window.location.reload();
                            });    
                        });
                    }
                    else {
                        setErrorMessage('Please check username and password...');
                        setErrorMessageVisibility(true);
                    }
                })
                .catch(error => {
                    setErrorMessage('Please check username and password...');
                    setErrorMessageVisibility(true);
                });
            }).catch(error => {
                setErrorMessage('User authentication start error...');
                setErrorMessageVisibility(true);
            });
        }
        else {
            setErrorMessage('Please check username and password...');
            setErrorMessageVisibility(true);
        }
    };

    const passVisibility = () => {
        const newVisibilityState = passVisible === 'password' ? 'text' : 'password';
        setPassVisible(newVisibilityState);
    };

    const hideErrorMessage = () => {
        setErrorMessage('');
        setErrorMessageVisibility(false);
    };

    return (
        <div className="loginScreen">
            <Container className="panel">
                <Row className="justify-content-md-center">
                    <Col>
                        <div className="loginForm">
                            <figure className="logo" />
                            <p className="subtitle">Sign in</p>
                            <Form.Group className="inputGroup">
                                <FontAwesomeIcon className="inlineIcon" icon={faEnvelope} />
                                <Form.Control className="textInputLogin" placeholder="username" type="text" onChange={onUserChange} />
                            </Form.Group>
                            <Form.Group className="inputGroup">
                                <FontAwesomeIcon className="inlineIcon" icon={faLock} />
                                <Form.Control className="textInputLogin" placeholder="password" type={passVisible} onChange={onPassChange} />
                                <Button type="button" variant="outline-primary" size="sm" className="showhidePass" onClick={passVisibility}>{passVisible === 'password' ? 'View' : 'Hide'}</Button>
                            </Form.Group>
                            <Form.Group className="inputGroup">
                                <Button type="button" variant="primary" size="lg" className="loginBtn" onClick={authUser}>
                                    {!loading ? 'Sign in' : <FontAwesomeIcon icon={faSpinner} spin /> }
                                </Button>
                            </Form.Group>
                            <span>New day, new opportunities in city sightseeing. Have a lot of joy and costumer today.</span>
                            <small>
                                any problem?
                                <a href="tel:+363012341212">+36 30 1234 12 12</a>
                            </small>
                        </div>
                    </Col>
                </Row>
            </Container>
            <ToastContainer position="top-end">
                <Toast bg="light" show={errorMessageVisibility} delay={3000} autohide={true} onClose={hideErrorMessage}>
                    <ToastHeader><strong className="me-auto">Authentication error</strong></ToastHeader>
                    <ToastBody>
                        {errorMessage}
                    </ToastBody>
                </Toast>
            </ToastContainer>
        </div>
    );
  }
  
  export default Login;
  