import './payment.scss'
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle, faClock, faExclamationCircle, faCancel, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect } from 'react';
import paymentStatus from '../../models/paymentStatus.model';
import PaymentService from './PaymentService';

const Payment = () => {

    const responseStatusDictionary: any = {
        'SUCCESS': 'Processing transaction...',
        'FINISHED': 'Successful payment',
        'CANCEL': 'Payment withdrawn',
        'CANCELLED': 'Payment withdrawn',
        'FAILURE': 'Payment failed',
        'TIMEOUT': 'Aborted due to timeout',
        'ERROR': 'An error occurred'
    };
    const responseStatusIconDictionary: any = {
        'SUCCESS': faClock,
        'FINISHED': faCheckCircle,
        'CANCEL': faCancel,
        'CANCELLED': faCancel,
        'FAILURE': faTimesCircle,
        'TIMEOUT': faClock,
        'ERROR': faExclamationCircle
    };

    const searchParams = new URLSearchParams(document.location.search);
    const [transactionID, setTransactionID] = useState<string>(searchParams.get('transactionID') as string);
    const paymentServ = new PaymentService(transactionID);
    const [paymentStatus, setPaymentStatus] = useState<paymentStatus>({transaction_status: 'SUCCESS', message: responseStatusDictionary['SUCCESS']} as paymentStatus);
    
    const getPaymentStatusChange = () => {
        paymentServ.getPaymentStatus().then((response: any) => {
            const paymentResponse: paymentStatus = {...response} as paymentStatus;
            paymentResponse.message = responseStatusDictionary[paymentResponse.transaction_status];
            const price: number = parseFloat(paymentResponse.amount);
            paymentResponse.amount = price.toFixed(2);
            if (paymentResponse.transaction_status === 'FINISHED') {
                paymentServ.closeTransaction(paymentResponse.ref);
            }
            else if (paymentResponse.transaction_status === 'SUCCESS') {
                let timer = setTimeout(() => {
                    window.location.reload();
                    clearTimeout(timer);
                }, 1000);
            }
            setPaymentStatus(paymentResponse);
        }).catch(error => {
            console.log(error);
        });
    }

    useEffect(() => {
        getPaymentStatusChange();
    }, [transactionID]);
    
    return (
        <div className="loginScreen">
            <Container className="panel">
                <Row className="justify-content-md-center">
                    <Col className="logo">
                        <figure className="loginPerson" />
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col>
                        <div className="payment-response-container">
                            <p><FontAwesomeIcon className={paymentStatus.transaction_status + ' statusIcon'} icon={responseStatusIconDictionary[paymentStatus.transaction_status]} /></p>
                            <p className="statusText">{paymentStatus.message}</p>
                            { paymentStatus.transaction_status !== 'SUCCESS' &&
                                <>
                                    <p className="detailsText">Order ID: <strong>{paymentStatus.description}</strong></p>
                                    <p className="detailsText">Transaction ID: {paymentStatus.transaction_id}</p>
                                    <p className="detailsText">Amount: {paymentStatus.amount} {paymentStatus.currency}</p>
                                </>
                            }
                            { paymentStatus.transaction_status === 'FINISHED' &&
                                <>
                                    <p className="detailsText">We have sent your invoice and ticket to your email address!</p>
                                    <p className="statusText">Thank you for your purchase!</p>
                                </>
                            }
                            { paymentStatus.transaction_status === 'SUCCESS' &&
                                <>
                                    <p className="detailsText">The transaction is being processed, please be patient!</p>
                                    <FontAwesomeIcon icon={faSpinner} spin />
                                    <p className="statusText">PLEASE DO NOT CLOSE THIS WINDOW!</p>
                                </>
                            }
                            { paymentStatus.transaction_status !== 'FINISHED' && paymentStatus.transaction_status !== 'SUCCESS' &&
                                <>
                                    <p className="detailsText"><strong>Transaction cancelled. No money has been transferred to his account.</strong></p>
                                    <p className="detailsText"><small>If necessary, please start the payment process from the beginning!</small></p>
                                </>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
  }
  
  export default Payment;
  