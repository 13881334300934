import './QuantityBox.scss'
import { useEffect, useState } from 'react';
import config from '../../config.json';

const QuantityBox = (props: any) => {
    const defaultPrice = props.defaultValue * props.price;
    const [itemPrice, setItemPrice] = useState(defaultPrice);
    const [itemQnt, setItemQnt] = useState(props.defaultValue);

    const decrementQNT = () => {
        if (props.defaultValue > 0) {
            const newQnt = props.defaultValue - 1;
            setItemQnt(newQnt);
            const calculatedPrice = newQnt * props.price;
            setItemPrice(calculatedPrice);
            props.callbackHandler(newQnt,props.price);
        }
        else {
            setItemPrice(0);
        }
    }

    const incrementQNT = () => {
        const newQnt = props.defaultValue + 1;
        setItemQnt(newQnt);
        const calculatedPrice = newQnt * props.price;
        setItemPrice(calculatedPrice);
        props.callbackHandler(newQnt,props.price);
    }

    let subs: any;
    if ('subproducts' in props && props.subproducts !== undefined && props.subproducts.length > 0) {
        subs = props.subproducts.map((item: any, index: any) => {
            return(
                <li>{item.label}</li>
            )
        });
    }

    useEffect(() => {
        if (props.defaultValue > 0) {
            const calculatedPrice = parseFloat(props.price) * itemQnt;
            setItemPrice(calculatedPrice);
        }
    },[props]);

    return(
        <table className="qntTable">
            <tbody>
                <tr>
                    <td className="qntInfo">
                        <strong>{props.label}</strong><br />
                        {props.currency === 'EUR' ? parseFloat(props.price).toFixed(2) : props.price} {props.currency === 'EUR' ? config.currencySigns.EUR : config.currencySigns.HUF} / person<br />
                        <ul>
                            { subs }
                        </ul>
                    </td>
                    <td className="qntControll">
                        <button className="qntButton" onClick={ decrementQNT }>-</button>
                        <small className="qntCounter">{props.defaultValue}</small>
                        <button className="qntButton" onClick={ incrementQNT }>+</button><br />
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default QuantityBox;