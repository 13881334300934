import './Cart.scss';
import { useState, useEffect } from 'react';
import ProductModel from '../../models/product.model';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import config from '../../config.json';

const Cart = (props: any) => {
    const [items, setItems] = useState<ProductModel[]>([]);
    const [sum, setSum] = useState(0);
    const [sumEUR, setSumEUR] = useState(0);

    const decrementAdultQNT = (id: number) => {
        if (items[id].adult > 1) {
            const newAdult = items[id].adult-1;
            const newAdultPrice = newAdult * items[id].adultPrice;
            const updatedCart = items.map((row,index) => {
                if (index === id) {
                    return {...row, adult: newAdult, adultPrice: items[id].adultPrice, sumPrice: newAdultPrice };
                }
                return row;
            });
            setItems(updatedCart);
            props.callbackHandler(updatedCart);
        }
    };

    const incrementAdultQNT = (id: number) => {
        const newAdult = items[id].adult+1;
        const newAdultPrice = newAdult * items[id].adultPrice;
        const updatedCart = items.map((row,index) => {
            if (index === id) {
                return {...row, adult: newAdult, adultPrice: items[id].adultPrice, sumPrice: newAdultPrice };
            }
            return row;
        });
        setItems(updatedCart);
        props.callbackHandler(updatedCart);
    };

    const decrementChildQNT = (id: number) => {
        if (items[id].child > 1) {
            const newChild = items[id].child-1;
            const newChildPrice = newChild * items[id].netChildPrice;
            const updatedCart = items.map((row,index) => {
                if (index === id) {
                    return {...row, child: newChild, childPrice: newChildPrice, sumPrice: items[id].adultPrice+newChildPrice };
                }
                return row;
            });
            setItems(updatedCart);
            props.callbackHandler(updatedCart);
        }
    };

    const incrementChildQNT = (id: number) => {
        const newChild = items[id].child+1;
        const newChildPrice = newChild * items[id].netChildPrice;
        const updatedCart = items.map((row,index) => {
            if (index === id) {
                return {...row, child: newChild, childPrice: newChildPrice, sumPrice: items[id].adultPrice+newChildPrice };
            }
            return row;
        });
        setItems(updatedCart);
        props.callbackHandler(updatedCart);
    };

    const deleteItemFromCart = (id: number) => {
        var itemsList = [...items];
        itemsList.splice(id,1);
        setItems(itemsList);
        props.callbackHandler(itemsList);
    };

    useEffect(() => {
        setItems(props.cart);
    },[props]);

    useEffect(() => {
        let bigsum = 0;
        let bigsumeur = 0;
        items.forEach((row) => {
            bigsum += row.sumPrice;
            bigsumeur += Math.round(((row.sumPrice / row.exchangeRate)*100))/100;
        });
        setSum(bigsum);
        setSumEUR(bigsumeur);
    },[items]);

    const list = items.map((item: any, index: number) => {
        return(
            <div className="qntSum" key={index}>
                <div className="cartProduct">
                    {item.product} <FontAwesomeIcon className="trashIcon" icon={faTrash} onClick={ () => deleteItemFromCart(index)} title="Delete" />
                </div>
                <table className="qntTable">
                    <tbody>
                        <tr>
                            <td className="qntInfo">
                                <strong>Persons</strong><br />
                                {props.currency === 'EUR' ? item.adultPrice.toFixed(2) : item.adultPrice} {props.currency === 'EUR' ? config.currencySigns.EUR : config.currencySigns.HUF} / person
                            </td>
                            <td className="qntControll">
                                <button className="qntButton" onClick={ () => decrementAdultQNT(index) }>-</button>
                                <small className="qntCounter">{item.adult}</small>
                                <button className="qntButton" onClick={ () => incrementAdultQNT(index) }>+</button><br />
                                {props.currency === 'EUR' ? item.sumPrice.toFixed(2) : item.sumPrice} {props.currency === 'EUR' ? config.currencySigns.EUR : config.currencySigns.HUF}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    });

    return(
        <div className="cartContainer">
            { items.length > 0 && 
            <label className="formLabel firstLabel">Cart</label>
            }
            {list}
            { sum > 0 && items.length > 0 && 
                <div className="qntSum">
                    <h5>Total payable:</h5>
                    {props.currency === 'EUR' ? sum.toFixed(2) : sum} {props.currency === 'EUR' ? config.currencySigns.EUR : config.currencySigns.HUF}<br />
                </div>
            }
        </div>
    );
};

//<small>(Exchange rate: 1 EUR = {items[0].exchangeRate} HUF)</small>
export default Cart;