import './Alreadypaid.scss'
import { Container, Row, Col } from "react-bootstrap";

const Alreadypaid = () => {

    return (
        <div className="loginScreen">
            <Container className="panel">
                <Row className="justify-content-md-center">
                    <Col className="logo">
                        <figure className="loginPerson" />
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col>
                        <div className="payment-response-container">
                            <p className="statusText">ATTENTION!</p>
                            <p className="detailsText">This ticket purchase process has already been completed successfully.</p>
                            <p className="detailsText">Please check your email account to download the ticket. If you did not receive the email, please contact us!</p>
                            <p className="statusText">Thank you for your purchase!</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
  }
  
  export default Alreadypaid;
  