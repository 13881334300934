import './Order.scss';
import { useState, useEffect } from 'react';
import ProductModel from '../../models/product.model';
import { Row, Col, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import userData from '../../models/userModel';
import OrderService from './OrderService';
import OrderModel from '../../models/order-model';

const Order = (props: any) => {

    const [cart, setCart] = useState<ProductModel[]>([]);
    const [order, setOrder] = useState<OrderModel>({ currency: '', paymentMethod: '' });
    const [email, setEmail] = useState('');
    const storedUserData: any = localStorage.getItem('userData');
    const [user, setUser] = useState<userData>(JSON.parse(storedUserData) as userData);
    const [pdfLink, setPdfLink] = useState('');
    const [pdfList, setPdfList] = useState<string[]>([]);
    const [finalizeRunning, setFinalizeRunning] = useState(false);
    const [finalizeShow, setFinalizeShow] = useState(true);
    var invoices: any = [];

    const orderService = new OrderService(user);

    const onEmailChange = (event: any) => {
        setEmail(event.target.value);
    }

    const createInvoice = () => {
        let list: any = [];
        setFinalizeRunning(true);
        cart.forEach(async (row) => {
            for (let i = 0; i < row.adult; i++) {
                list.push(row);
            }
        });
        generateInvoice(list,0).then(() => {
            props.callbackHandler();
            setFinalizeRunning(false);
            setFinalizeShow(false);
        });
    }

    const generateInvoice = async (rows: any, index: number) => {
        await orderService.generateTicketsByCartData(rows[index],order?.currency,order?.paymentMethod).then(async (link: any) => {
            setPdfLink(link);
            setPdfList(prev => [...prev,link]);
            console.log('Invoice generating completed: ',link);
            if (index < rows.length - 1) {
                generateInvoice(rows, index + 1);
            }
        });
    }

    const startPayment = () => {
        let ids: any = [];
        let list: any = [];
        cart.forEach(async (row) => {
            for (let i = 0; i < row.adult; i++) {
                list.push(row);
            }
        });
        generatePaySessions(list,0,invoices);
    }

    const generatePaySessions = async (rows: any, index: number, invoiceList: any) => {
        await orderService.generateTransactionByCartData(rows[index],order?.currency).then(async (invID: any) => {
            invoiceList.push(invID);
            if (index < rows.length - 1) {
                generatePaySessions(rows, index + 1,invoiceList);
            }
            else {
                orderService.sendPaymentMail(invoiceList,email).then(() => {
                    console.log('Payment email sended...');
                    props.callbackHandler();
                    props.closeSession();
                });    
            }
        });
    }

    const sendTicket = () => {
        if (pdfList.length > 0 && email !== '') {
            orderService.sendTicketByEmail(pdfList,email).then(async (response: any) => {
                props.closeSession();
            });
            console.log('Email sender...');
        }
        else {
            console.log('Email sender error: mandatory fields are empty...');
        }
    }

    const base64toBlob = (data: string) => {
        const bytes = atob(data);
        let length = bytes.length;
        let out = new Uint8Array(length);
    
        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }
    
        return new Blob([out], { type: 'application/pdf' });
    }

    const printTicket = async (pdfLink?: string) => {
        await orderService.downloadPDFByFilename(pdfLink).then(async (response: any) => {
            const blob = base64toBlob(response);
            const url = URL.createObjectURL(blob);
            window.open(url);
            //props.closeSession();
        });
    }

    const gyozelem = () => {
        return pdfList.map((pdf) => {
            const jegy = pdf.split('/').slice(-1);
            return (<Row>
                <Col>
                    <button className="btn cart-btn" onClick={() => printTicket(pdf) }>Printing of ticket number {jegy[0]}</button>
                </Col>
            </Row>)
        });
    }

    useEffect(() => {
        gyozelem();
    },[pdfLink,pdfList]);

    useEffect(() => {
        setCart(props.cart);
        const orderData: OrderModel = { currency: props.currency, paymentMethod: props.paymentMethod };
        setOrder(orderData);
    },[props]);

    return(
        <div className="orderContainer">
                <>
                    { order?.currency !== '' && order?.paymentMethod !== '' && cart.length > 0 && 
                    <>
                        <label className="formLabel firstLabel">Payment</label>
                    </>
                    }

                    { order?.currency !== '' && order?.paymentMethod === 'CASH' && pdfLink === '' && cart.length > 0 && !finalizeRunning && finalizeShow && 
                    <>
                        <Row>
                            <Col>
                                <button className="btn cart-btn" onClick={() => createInvoice() }>Finalize payment</button>
                            </Col>
                        </Row>
                    </>
                    }

                    { order?.currency !== '' && order?.paymentMethod === 'CASH' && pdfLink === '' && cart.length > 0 && finalizeRunning && finalizeShow && 
                    <>
                        <Row>
                            <Col>
                                <button className="btn cart-btn" disabled={true}><FontAwesomeIcon icon={faSpinner} spinPulse /> Finalize payment</button>
                            </Col>
                        </Row>
                    </>
                    }

                    { order.currency !== '' && order.paymentMethod !== '' && (pdfLink !== '' || order?.paymentMethod === 'CARD') && cart.length > 0 && 
                    <>
                        <Row>
                            <Col>
                                <Form.Group className="inputGroupOrder">
                                    <FontAwesomeIcon className="inlineIconOrder" icon={faEnvelope} />
                                    <Form.Control className="textInputOrder" placeholder="email address" type="text" onChange={onEmailChange} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p className='paymentSupporter'>Payment solution from</p>
                                <figure className="footerLogo" />
                            </Col>
                        </Row>
                    </>
                    }

                    { order?.currency !== '' && order?.paymentMethod === 'CARD' && pdfLink === '' && cart.length > 0 && email.length > 0 && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email) &&
                    <>
                        <Row>
                            <Col>
                                <button className="btn cart-btn" onClick={() => startPayment() }>Start online payment</button>
                            </Col>
                        </Row>
                    </>
                    }

                    { order?.currency !== '' && order?.paymentMethod !== '' && pdfLink !== '' && cart.length > 0 && email.length > 0 && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email) &&
                    <>
                        <Row>
                            <Col>
                                <button className="btn cart-btn" onClick={() => sendTicket() }>Send ticken by email</button>
                            </Col>
                        </Row>
                    </>
                    }

                    { order?.currency !== '' && order?.paymentMethod === 'CASH' && pdfLink !== '' && cart.length > 0 &&
                    <>
                        <Row>
                            <Col>
                                <div className="divider"></div>
                                { gyozelem() }
                            </Col>
                        </Row>
                    </>
                    }

                </>
        </div>
    )
}

export default Order;