import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.scss';
import Layout from './components/Layout';
import Dashboard from './components/Dashboard/Dashboard';
import Nopage from './components/Nopage';
import Login from './components/Login/Login';
import Payment from './components/Payment/Payment';
import Alreadypaid from './components/Alreadypaid/Alreadypaid';
import { useState, useEffect } from 'react';
import userData from './models/userModel';

const App = () => {
  const [user, setUser] = useState<userData>({} as userData);

  useEffect(() => {
    const info = localStorage.getItem('userData') ? localStorage.getItem('userData') : '';
    const storedUserData = info;
    if (storedUserData) {
      setUser(JSON.parse(storedUserData));
    }
  },[]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={ user.login ? <Dashboard /> : <Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/alreadypaid" element={<Alreadypaid />} />
          <Route path="*" element={<Nopage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
