import ProductModel from '../../models/product.model';
import userData from '../../models/userModel';
import invoiceItem from '../../models/invoice-item.model';
import invoicesAddPaymentModel from '../../models/invoicesAddPayment.model';
import config from '../../config.json';

class OrderService {
    private cart: any;
    private user: userData;
    private orderCurrency: string = '';
    private orderCurrencyID: number = 0;
    private multicurrencyTX: number = 1;
    private orderPaymentMethod: number = 0;
    public invoiceID: string = '';
    private paymentsMode: any;

    public pdfInvoiceLink: string = '';

    public constructor(userData: userData) {
        this.user = userData;
        const payModes = localStorage.getItem('paymentsMode');
        this.paymentsMode = payModes? JSON.parse(payModes) : null;
    }

    public async generateTicketsByCartData(cartData: ProductModel, currency: string, paymentMethod: string) {
        return new Promise(async resolve => {
            this.cart = cartData;
            this.orderCurrency = currency;
            const currenciesData: any = localStorage.getItem('currencies');
            const currencies = JSON.parse(currenciesData);
            this.orderCurrencyID = currencies[this.orderCurrency];
            if (paymentMethod === 'CASH') {
                this.orderPaymentMethod = this.paymentsMode[0].id;
            }
            else {
                this.orderPaymentMethod = this.paymentsMode[1].id;
            }

            await this.generateInvoice().then(async (invoiceid: any) => {
                this.invoiceID = invoiceid;
                console.log('Invoice generated...');
                if (this.cart.subProducts !== undefined && this.cart.subProducts.length > 0) {
                    await this.addMultipleInvoiceItems(this.cart,invoiceid).then(async () => {
                        console.log('Invoice items added...');
                        await this.validateInvoice(invoiceid).then(async (result) => {
                            console.log('Validation completed: ',result);
                            if (this.orderPaymentMethod === this.paymentsMode[0].id) {
                                await this.addPaymentInformationsToInvoce(invoiceid).then(async () => {
                                    console.log('Payment informations added...');
                                    await this.getPrintableDocument(invoiceid).then((pdfDocumentLink: any) => {
                                        console.log('PDF invoice download started...');
                                        this.pdfInvoiceLink = pdfDocumentLink;
                                        resolve(pdfDocumentLink);
                                    });    
                                });
                            }            
                        });    
                    });
                }
                else {
                    await this.addInvoceItem(this.cart,invoiceid).then(async () => {
                        console.log('Invoice items added...');
                        await this.validateInvoice(invoiceid).then(async (result) => {
                            console.log('Validation completed: ',result);
                            if (this.orderPaymentMethod === this.paymentsMode[0].id) {
                                await this.addPaymentInformationsToInvoce(invoiceid).then(async () => {
                                    console.log('Payment informations added...');
                                    await this.getPrintableDocument(invoiceid).then((pdfDocumentLink: any) => {
                                        console.log('PDF invoice download started...');
                                        this.pdfInvoiceLink = pdfDocumentLink;
                                        resolve(pdfDocumentLink);
                                    });    
                                });
                            }            
                        });        
                    });
                }
            });
        });
    }

    public async generateTransactionByCartData(cartData: ProductModel, currency: string) {
        return new Promise(async resolve => {
            this.cart = cartData;
            this.orderCurrency = currency;
            const currenciesData: any = localStorage.getItem('currencies');
            const currencies = JSON.parse(currenciesData);
            this.orderCurrencyID = currencies[this.orderCurrency];
            this.orderPaymentMethod = this.paymentsMode[1].id;
    
            await this.generateInvoice().then(async (invoiceid: any) => {
                this.invoiceID = invoiceid;
                console.log('Invoice generated...');
                if (this.cart.subProducts !== undefined && this.cart.subProducts.length > 0) {
                    await this.addMultipleInvoiceItems(this.cart,invoiceid).then(async () => {
                        console.log('Invoice items added...');
                        await this.validateInvoice(invoiceid).then(async (result) => {
                            console.log('Validation completed');
                            resolve(invoiceid);
                        });    
                    });
                }
                else {
                    await this.addInvoceItem(this.cart,invoiceid).then(async () => {
                        console.log('Invoice items added...');
                        await this.validateInvoice(invoiceid).then(async (result) => {
                            console.log('Validation completed');
                            resolve(invoiceid);
                        });        
                    });
                }
                /*await this.addInvoceItem(this.cart,invoiceid).then(async () => {
                    console.log('Invoice items added...');
                    await this.validateInvoice(invoiceid).then((result) => {
                        console.log('Validation completed');
                        resolve(invoiceid);
                    });        
                });*/    
                console.log('Invoice generated...');
            });
        });
    }

    public async addMultipleInvoiceItems(cart: any, invoiceid: any) {
        return new Promise(async resolve => {
            cart.subProducts.forEach(async (product: any) => {
                const item = {
                    pointOfSale: cart.pointOfSale,
                    id: product.id,
                    product: product.label,
                    netAdultPrice: product.price,
                    netChildPrice: product.price,
                    tax: product.tva_tx,
                    adult: cart.adult,
                    child: cart.child,
                    adultPrice: product.price,
                    childPrice: product.price,
                    sumPrice: product.price,
                    exchangeRate: cart.exchangeRate,
                    sumPriceInEUR: cart.sumPriceInEUR
                };
                await this.addInvoceItem(item,invoiceid);
            });
            setTimeout(() => resolve('ok'), 2000);
        });
    }

    private async generateInvoice() {
        return new Promise(resolve => {
            const invoiceData = {
                socid: '1',
                mode_reglement_id: this.orderPaymentMethod,
                entity_id: this.user.entity,
                array_options_react_facture: this.user.groupID,
                multicurrency_code: this.orderCurrency,
                fk_multicurrency: this.orderCurrencyID,
                multicurrency_tx: this.multicurrencyTX,
                type: 7
            };
    
            const request = {
                method: "POST",
                headers: { 'Content-Type': 'application/json', 'DOLAPIKEY': this.user.token },
                body: JSON.stringify(invoiceData)
            };
    
            fetch(config.endpoint + 'invoices',request)
            .then(async response => {
                const data = await response.json();
                resolve(data);
            }).catch(error => {
                console.log('Error message: ', error);
            });    
        });
    }

    private async addInvoiceItemsList() {
        await Promise.all(
            this.cart.map(async (item: any) => {
                //await this.addInvoceItem(item);
            })
        );
    }

    private async addInvoceItem(item: any,invID: any) {
        return new Promise<void>(resolve => {
                const request = {
                    method: "POST",
                    headers: { 'Content-Type': 'application/json', 'DOLAPIKEY': this.user.token },
                    body: ''
                };
                const invoiceItemData: invoiceItem = { 
                    desc: '', 
                    subprice: item.netAdultPrice, 
                    qty: 1, 
                    tva_tx: item.tax, 
                    localtax1_tx: '0', 
                    localtax2_tx: '0', 
                    fk_product: item.id, 
                    remise_percent: '0', 
                    fk_code_ventilation: '0', 
                    info_bits: '0', 
                    product_type: '1', 
                    rang: '-1', 
                    special_code: '0',
                    pa_ht: '0',
                    situation_percent: '100'
                };
                request.body = JSON.stringify(invoiceItemData);
                fetch(config.endpoint + 'invoices/' + invID + '/lines', request)
                .then(response => {
                    resolve();
                }).catch(error => {
                    console.log('Error message: ', error);
                });
            }
        );
    }

    private async addPaymentInformationsToInvoce(invID: any) {
        return new Promise((resolve, reject) => {
            const accountsData: any = localStorage.getItem('accounts');
            const accounts = JSON.parse(accountsData);

            const requestBody: invoicesAddPaymentModel = {
                datepaye: Math.floor(Date.now() / 1000).toString(),
                paymentid: this.orderPaymentMethod,
                closepaidinvoices: 'yes',
                accountid: accounts[this.orderCurrency]
            };
            const request = {
                method: "POST",
                headers: { 'Content-Type': 'application/json', 'DOLAPIKEY': this.user.token },
                body: JSON.stringify(requestBody)
            };
            fetch(config.endpoint + 'invoices/' + invID + '/payments',request)
            .then(async response => {
                const data = await response.json();
                resolve(data);
            }).catch(error => {
                console.log('Error message: ', error);
                reject(error);
            });    
        });
    }

    private async validateInvoice(invID: any) {
        const requestBody = {
            notrigger: 1
        };
        return new Promise((resolve,reject) => {
            const request = {
                method: "POST",
                headers: { 'Content-Type': 'application/json', 'DOLAPIKEY': this.user.token },
                body: JSON.stringify(requestBody)
            };

            fetch(config.endpoint + 'invoices/' + invID + '/validate',request)
            .then(async response => {
                const data = await response.json();
                resolve(data);
            })
            .catch(error => {
                console.log('Error while validating invoice: ',error);
                reject(error);
            });
        });
    }

    public getPrintableDocument(invID: any) {
        return new Promise((resolve, reject) => {
            const request = {
                method: "GET",
                headers: { 'Content-Type': 'application/json', 'DOLAPIKEY': this.user.token }
            };

            fetch(config.endpoint + 'invoices/' + invID + '?contact_list=1',request)
            .then(async response => {
                const data = await response.json();
                if ('last_main_doc' in data) {
                    const documentDownloadURL = data.last_main_doc.replace('4/facture/','');
                    resolve(documentDownloadURL);
                }
            })
            .catch(error => {
                console.log('Error while validating invoice: ',error);
                reject(error);
            });
        });
    }

    public downloadPDFByFilename(factureFilename: any) {
        return new Promise((resolve, reject) => {
            const request = {
                method: "GET",
                headers: { 
                    'Content-Type': 'application/json', 
                    'DOLAPIKEY': this.user.token
                }
            };

            fetch(config.endpoint + 'documents/download?modulepart=facture&original_file=' + encodeURI(factureFilename),request)
            .then(async response => {
                const data = await response.json();
                resolve(data.content);
            })
            .catch(error => {
                console.log('Error while downloading PDF file');
                reject(error);
            });
        });
    }

    public sendTicketByEmail(factureFilename: any, emailAddress: any) {
        return new Promise((resolve, reject) => {
            const requestBody = {
                id: factureFilename,
                emailaddress: emailAddress
            };
            const request = {
                method: "POST",
                headers: { 
                    'Content-Type': 'application/json', 
                    'DOLAPIKEY': this.user.token 
                },
                body: JSON.stringify(requestBody)
            };

            fetch(config.endpoint + 'simplepayapi/logs/sendmail',request)
            .then(async response => {
                const data = await response.json();
                resolve(data);
            })
            .catch(error => {
                console.log('Error while sending email');
                reject(error);
            });
        });
    }

    public async sendPaymentMail(invoiceid: string[], email: string) {
        return new Promise(resolve => {
            const invoiceData = {
                id: invoiceid, 
                emailaddress: email,
                apikey: this.user.token
            };

            const request = {
                method: "POST",
                headers: { 'Content-Type': 'application/json', 'DOLAPIKEY': this.user.token },
                body: JSON.stringify(invoiceData)
            };

            fetch(config.endpoint + 'simplepayapi/logs/paymail',request)
            .then(async response => {
                const data = await response.json();
                resolve(data);
            }).catch(error => {
                console.log('Error message: ', error);
            });
        });
    }
}

export default OrderService;