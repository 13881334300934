import { Outlet } from "react-router";

function Layout() {
  return (
      <>
        <Outlet />
      </>
  );
}

export default Layout;
//<footer><figure className="footerLogo" /></footer>
