import { Row, Col, Container, Navbar, NavbarBrand } from "react-bootstrap";
import ComboListBox from "../ComboListBox/ComboListBox";
import QuantityBox from "../QuantityBox/QuantityBox";
import './dashboard.scss';
import { useState, useEffect } from 'react';
import optionData from "../ComboListBox/ComboListBox.interface";
import ProductModel from "../../models/product.model";
import Cart from "../Cart/Cart";
import Order from '../Order/Order';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut } from '@fortawesome/free-solid-svg-icons';
import { faHome } from "@fortawesome/free-solid-svg-icons";
import userData from "../../models/userModel";
import exchangeRate from "../../models/exchangeRate.model";
import DashboardService from "./DashboardService";
import config from '../../config.json';

const Dashboard = () => {
  const defaultFormData: ProductModel = {
    pointOfSale: '',
    product: '',
    id: '0',
    netAdultPrice: 0,
    netChildPrice: 0,
    tax: 0,
    adult: 1,
    child: 0,
    adultPrice: 0,
    childPrice: 0,
    sumPrice: 0,
    exchangeRate: 0,
    sumPriceInEUR: 0,
    subProducts: []
  };

  const [formData, setFormData ] = useState(defaultFormData);
  const [cart, setCart] = useState<ProductModel[]>([]);
  const storedUserData: any = localStorage.getItem('userData');
  const [user, setUser] = useState<userData>(JSON.parse(storedUserData) as userData);

  const [hufBtnClass, setHufBtnClass] = useState('btn cart-btn-off');
  const [eurBtnClass, setEurBtnClass] = useState('btn cart-btn-off');
  const [cashBtnClass, setCashBtnClass] = useState('btn cart-btn-off');
  const [cardBtnClass, setCardBtnClass] = useState('btn cart-btn-off');
  const [cashBtnDisabled, setCashBtnDisabled] = useState(true);
  const [cardBtnDisabled, setCardBtnDisabled] = useState(true);

  const [hufBtnDisabled, setHufBtnDisabled] = useState(false);
  const [eurBtnDisabled, setEurBtnDisabled] = useState(false);

  const [currency, setCurrency] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');

  const [closed, setClosed] = useState(false);
  const [closeOrder, setCloseOrder] = useState(false);

  const dashboardService = new DashboardService(user,currency);
  const [productsList, setProductsList] = useState<optionData[]>();
  const rate: exchangeRate = dashboardService.rateData;

  const setProduct = (selectedItem: any) => {
    const defaultSumPrice = selectedItem.adultPrice;
    const eurPrice = Math.round(((defaultSumPrice / rate.rate)*100))/100;
    const updatedData = { 
      product: selectedItem.value, 
      id: selectedItem.id,
      netAdultPrice: selectedItem.netAdultPrice, 
      netChildPrice: selectedItem.netChildPrice, 
      tax: selectedItem.tax,
      adult: 1, 
      adultPrice: selectedItem.adultPrice, 
      childPrice: selectedItem.childPrice, 
      sumPrice: defaultSumPrice,
      exchangeRate: rate.rate,
      sumPriceInEUR: eurPrice,
      subProducts: selectedItem.subproducts
    };
    setFormData(formData => ({
      ...formData, 
      ...updatedData
    }));
  }

  const setAdult = (qntNumber: number, price: number) => {
    const sumPrice = price * qntNumber;
    const eurPrice = Math.round(((sumPrice / rate.rate)*100))/100;
    const updatedData = { adult: qntNumber, adultPrice: price, sumPrice: sumPrice, exchangeRate: rate.rate, sumPriceInEUR: eurPrice };
    setFormData(formData => ({
      ...formData, 
      ...updatedData
    }));
  }

  const setChild = (qntNumber: number, price: number) => {
    const sumPrice = formData.adultPrice+price;
    const eurPrice = Math.round(((sumPrice / rate.rate)*100))/100;
    const updatedData = { child: qntNumber, childPrice: price, sumPrice: sumPrice, exchangeRate: rate.rate, sumPriceInEUR: eurPrice };
    setFormData(formData => ({
      ...formData, 
      ...updatedData
    }));
  }

  const addProductToCart = () => {
    setHufBtnDisabled(true);
    setEurBtnDisabled(true);
    setCart(current => [...current, formData]);
    defaultFormData.pointOfSale = formData.pointOfSale;
    setFormData(defaultFormData);
  }

  const updateCart = (cart: any) => {
    setCart(cart);
  }

  const logout = () => {
    localStorage.removeItem('userData');
    window.location.reload();
  };

  const setSelectedCurrency = async (selected: string) => {
    setCurrency(selected);
    defaultFormData.pointOfSale = formData.pointOfSale;
    setFormData(defaultFormData);
    await dashboardService.getProductsList(selected).then((list: any) => {
      setProductsList([...list]);
    });
    setPaymentMethod('');
    if (selected === 'HUF') {
        setHufBtnClass('btn cart-btn-on');
        setEurBtnClass('btn cart-btn-off');
        setCashBtnDisabled(false);
        setCardBtnDisabled(false);
        setCashBtnClass('btn cart-btn-off');
        setCardBtnClass('btn cart-btn-off');
    }
    else {
        setEurBtnClass('btn cart-btn-on');
        setHufBtnClass('btn cart-btn-off');
        setCashBtnDisabled(false);
        setCardBtnDisabled(true);
        setCashBtnClass('btn cart-btn-off');
        setCardBtnClass('btn cart-btn-off hideme');
    }
  }

  const setSelectedPayment = (selected: string) => {
      setPaymentMethod(selected);
      if (selected === 'CASH') {
          setCashBtnClass('btn cart-btn-on');
          setCardBtnClass('btn cart-btn-off');
      }
      else {
          setCardBtnClass('btn cart-btn-on');
          setCashBtnClass('btn cart-btn-off');
      }
  }

  const finishSession = () => {
    setClosed(true);
  }

  const closeOrderMethod = () => {
    setCloseOrder(true);
  }

  const resetForms = () => {
    window.location.reload();
  }

  useEffect(() => {
  },[productsList]);

  useEffect(() => {
  },[cart]);

  return (
      <div className="dashboardScreen">
        <Navbar className="navigator">
          <Container className="fullwidth">
            <NavbarBrand href="/">
              <figure className="headerLogo" />
            </NavbarBrand>
            <div className="userProfile">
              <span className="userHello">
                Hello, {user.login}
                <small className="userType">{user.company_name}</small>
              </span>
              <img src="avatar.png" alt={user.login} className="userAvatar" />
              <FontAwesomeIcon className="exitIcon" icon={faSignOut} onClick={logout} title="Logout" />
            </div>
          </Container>
        </Navbar>

      {!closeOrder && 
      <>
        <div className="board">
          <Row>
            <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={12} xxs={12} className="board-card marged">
              <Row>
                <Col>
                    <div className="divider"></div>
                </Col>
              </Row>
              <>
                <label className="formLabel firstLabel">Choose payment type</label>
                <Row>
                    <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12} xxs={12}>
                        <button disabled={hufBtnDisabled} className={hufBtnClass} onClick={ () => setSelectedCurrency('HUF') }>HUF</button>
                    </Col>
                    <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12} xxs={12}>
                        <button disabled={eurBtnDisabled} className={eurBtnClass} onClick={ () => setSelectedCurrency('EUR') }>EUR</button>
                    </Col>
                </Row>
                <Row>
                    <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12} xxs={12}>
                        <button disabled={cashBtnDisabled} className={cashBtnClass} onClick={ () => setSelectedPayment('CASH') }>CASH</button>
                    </Col>
                    <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12} xxs={12}>
                        <button disabled={cardBtnDisabled} className={cardBtnClass} onClick={ () => setSelectedPayment('CARD') }>CARD</button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="divider"></div>
                    </Col>
                </Row>
              </>

              {currency !== '' && paymentMethod !== '' && 
                <>
                  <label className="formLabel">Choose service</label><ComboListBox listData={ productsList } id="product" callbackHandler={ setProduct }></ComboListBox>
                </>
              }
              {formData.product !== '' && 
                <>
                <QuantityBox id="adult" label="Adult" subproducts={formData.subProducts} price={formData.adultPrice} currency={currency} tax={formData.tax} defaultValue={ formData.adult } callbackHandler={ setAdult } />
                <div className="qntSum">
                  {currency === 'EUR' ? formData.sumPrice.toFixed(2) : formData.sumPrice} {currency === 'EUR' ? config.currencySigns.EUR : config.currencySigns.HUF}
                </div>
                </>
              }
              {formData.product !== '' && formData.sumPrice > 0 && 
                <>
                  <button className="btn cart-btn" onClick={addProductToCart}>Add to cart</button>
                </>
              }

              { closed && 
                <>
                  <div className="disabled-panel"></div>
                </>
              }
            </Col>

            <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={12} xxs={12} className="board-card marged">
              <Cart cart={ cart } currency={currency} callbackHandler={ updateCart } />
            </Col>

            <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={12} xxs={12} className="board-card">
              <Order cart={ cart } currency={ currency } paymentMethod={ paymentMethod } callbackHandler={ finishSession } closeSession={ closeOrderMethod } />
            </Col>
          </Row>
        </div>
      </>
      }

      {closeOrder && paymentMethod !== 'CARD' &&
      <>
        <div className="board">
          <Row>
            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} xxs={12}>
              <div className="close-container">
                <h3 className="centerizedText">
                  Thank you for your purchase!
                </h3>
                <button type="button" className="bigButton" onClick={ () => resetForms() }>
                  <FontAwesomeIcon className="homeIcon" icon={faHome} title="Goto home" />
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </>
      }

      {closeOrder && paymentMethod === 'CARD' &&
      <>
        <div className="board">
          <Row>
            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} xxs={12}>
              <div className="close-container">
                <h3 className="centerizedText">
                  Thank you for your purchase!
                </h3>
                <h4>The payment link required to finalize the purchase and it was sent by letter to the specified email address.</h4><br />
                <button type="button" className="bigButton" onClick={ () => resetForms() }>
                  <FontAwesomeIcon className="homeIcon" icon={faHome} title="Goto home" />
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </>
      }

      </div>
  );
}

export default Dashboard;
