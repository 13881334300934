import config from '../../config.json';
import invoicesAddPaymentModel from '../../models/invoicesAddPayment.model';
import userData from '../../models/userModel';

class PaymentService {

    public transactionID: string;
    public invoiceID: string = '';
    private orderPaymentMethod: number = 25;
    
    public constructor(transactionID: any) {
        this.transactionID = transactionID;
    }

    public async getPaymentStatus(): Promise<string> {
        return new Promise(resolve => {
            const request = {
                method: "GET",
                headers: { 'Content-Type': 'application/json' }
            };
            fetch(config.endpoint + "simplepayapi/logs/paymentstatus?transactionID=" + this.transactionID,request)
            .then(async response => {
                const data = await response.json();
                resolve(data);
            });
        });
    }

    public async getOrderIDS() {
        return new Promise((resolve, reject) => {
            const requestBody = {
                transactionID: this.transactionID
            };
            const request = {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(requestBody)
            };
            fetch(config.endpoint +'simplepayapi/logs/getorderids',request).then(async response => {
                const data = await response.json();
                resolve(data);
            }).catch(error => {
                console.log('Error message: ', error);
                reject(error);
            });
        });
    }

    public async closeTransaction(invoiceid: any) {
        this.invoiceID = invoiceid;
        await this.getOrderIDS().then(async (ids: any) => {
            await this.addPaymentInformationsToInvoce(ids,0).then(() => {
                console.log('Transaction close started...',ids);
            });
        });
    }

    private async sendTicketByEmail() {
        return new Promise((resolve, reject) => {
            const requestBody = {
                transactionId: this.transactionID
            };
            const request = {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(requestBody)
            };
            fetch(config.endpoint +'simplepayapi/logs/sendticketemail',request).then(async response => {
                const data = await response.json();
                resolve(data);
            }).catch(error => {
                console.log('Error message: ', error);
                reject(error);
            });
        });
    }

    private async addPaymentInformationsToInvoce(ids: any, index: number) {
        if (index < ids.length) {
            const accountsData: any = localStorage.getItem('accounts');
            const accounts = JSON.parse(accountsData);

            const requestBody: invoicesAddPaymentModel = {
                datepaye: Math.floor(Date.now() / 1000).toString(),
                paymentid: this.orderPaymentMethod,
                closepaidinvoices: 'yes',
                accountid: 9
            };
            const request = {
                method: "POST",
                headers: { 'Content-Type': 'application/json', 'DOLAPIKEY': 'HhUFln9t84rCNQY6CRo7lqo6Kw7c960K' },
                body: JSON.stringify(requestBody)
            };

            fetch(config.endpoint + 'invoices/' + ids[index] + '/payments',request)
            .then(async response => {
                this.addPaymentInformationsToInvoce(ids, index + 1);
            }).catch(error => {
                console.log('Error message: ', error);
            });
        }
        else {
            this.sendTicketByEmail();
        }
    }

}

export default PaymentService;